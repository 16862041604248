import LocomotiveScroll from 'locomotive-scroll';



const scroll = new LocomotiveScroll({
    el: document.querySelector('[data-scroll-container]'),
    smooth: true,
});



  // JavaScript to toggle mobile menu with animation and disable scrolling
  const menuBtn = document.getElementById("menuBtn");
  const body = document.body;
  const mobileMenu = document.getElementById("mobileMenu");
  const mobileMenuLinks = document.querySelectorAll("#mobileMenu a");

  let isMenuOpen = sessionStorage.getItem("isMenuOpen") === "true" || false;
  updateMobileMenu(isMenuOpen);

  menuBtn.addEventListener("click", () => {
    isMenuOpen = !isMenuOpen;
    updateMobileMenu(isMenuOpen);
    sessionStorage.setItem("isMenuOpen", isMenuOpen);
  });

  function updateMobileMenu(isOpen) {
    if (isOpen) {
      mobileMenu.style.height = "90vh";
      mobileMenu.style.visibility = "visible";
      mobileMenu.style.overflow = "auto";
      body.style.overflow = "hidden";
      scroll.stop();
    } else {
      mobileMenu.style.height = "0vh";
      mobileMenu.style.visibility = "hidden";
      mobileMenu.style.overflow = "hidden";
      body.style.overflow = "visible";
      scroll.start();
    }
  }

  // Close mobile menu when clicking on a link
  mobileMenuLinks.forEach(link => {
    link.addEventListener("click", (event) => {
      event.preventDefault();
      if (isMenuOpen) {
        // Verzögert das Schließen des Menüs um 300ms, um dem Browser Zeit zum Navigieren zu geben
        setTimeout(() => {
          window.location.href = event.target.href;
        }, 800);

        isMenuOpen = false;
          updateMobileMenu(false);
          sessionStorage.setItem("isMenuOpen", "false");
          body.style.overflow = "auto";

      }
    });
  });
